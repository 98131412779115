.container {
  position: relative;

  & .promo {
    display: inline-block;
    border-radius: 4px;
    background: #ffeeb3;
    padding: 2px 8px;
    margin-bottom: 16px;

    color: #db8016;

    font-size: 16px;
    font-weight: 600;
    line-height: 22px; 
    text-transform: uppercase;
  }

  & .info {
    display: flex;
    align-items: center;
  }

  & .close {
    position: absolute;
    top: -8px;
    right: -8px;
  }

  & .image {
    min-width: 80px;
    height: 80px;
    width: 80px;
    object-fit: contain;
  }

  & h2 {
    font-size: 20px;
    line-height: 24.2px;
    font-weight: 700;
    margin-left: 16px;
  }
}

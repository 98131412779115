.container {
  & p {
    color: #3c3c3c;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }

  & .btn {
    width: 100%;
    margin-top: 32px;
  }
}

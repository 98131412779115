.counter {
  width: 154px;
  height: 52px;
  background: #ffd100;
  border: 2px solid #000000;
  border-radius: 2000px;
  padding: 12px 28px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  &.disabled {
    background: #C8C8C8;
  }
}

.btn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.value {
  min-width: 28px;
  height: 28px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: #000000;
  margin-right: 14px;
  margin-left: 14px;
}

.secondary {
  &.counter {
    background-color: #fff;
  }
}

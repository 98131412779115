.container {
  display: flex;
  
  @media (max-width: 1120px) {
    flex-direction: column;
  }

  & .tapToOrderPanel {
    cursor: pointer;
    position: relative;
    width: 607px;
    min-width: 607px;
    background: #ffd100;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 1120px) {
      width: 100%;
      height: 50vh;
    }

    & h1,
    & button {
      font-size: 64px;
      line-height: 90px;
      font-weight: 700;
    }

    & h1 {
      margin-bottom: 16px;
    }

    & button {
      padding: 22px 44px;
      border-radius: 222px;
      border: none;
      cursor: pointer;
    }

    & img {
      position: absolute;
      bottom: 32px;
      left: 50%;
      transform: translateX(-50%);
      width: 300px;
      height: 149px;
      object-fit: contain;
    }

    & .version {
      position: absolute;
      bottom: 0;
      right: 0;

      margin-right: 5px;
      margin-bottom: 5px;

      font-size: 12px;
      color: #848484;
    }

    & .environment {
      color: red;
      text-transform: uppercase;
    }
  }
}

.errorModal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  & img {
    width: 45vw;
  }
}

.container {
  position: relative;
  width: 468px;
  margin: 0 auto;

  & .inputContainer {
    position: relative;

    & .showPassword {
      height: 24px;
      position: absolute;
      top: 20px;
      right: 26px;
    }

    & .inputKeyboard {
      display: flex;
      gap: 10px;
      position: absolute;
      transform: translate(-45%, 5%);
      width: 400px;
    }
  }

  & h5 {
    margin-bottom: 4px;
    color: #31383a;
    text-align: start;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
  }

  & input.input {
    height: 64px;
    width: 100%;
    line-height: 20px;
    font-size: 20px;
    padding: 22px 24px;
    border-radius: 5px;
    border: 1px solid #a5bac3;

    &_phone {
      padding-left: 48px;
    }
  }

  .error {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    font-size: 13px;
    color: #ff4545;
  }

  &.validation {
    & .error {
      display: inline;
    }

    & input {
      border: 1px solid #ff4545;
      color: #ff4545;
    }

    & input::-webkit-input-placeholder {
      color: #ff4545;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  & h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  & .tipsBox {
    display: flex;
    gap: 8px;
  }
}

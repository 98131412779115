.myTheme {
  height: 502px;

  & > div > div > div {
    height: 94px !important;

    & span {
      font-size: 24px;
    }
  }

  [data-skbtn="{alt}"],
  [data-skbtn="{default}"] {
    max-width: 200px !important;
  }
}

.container {
  position: fixed;
  left: 50%;
  bottom: 176px;
  transform: translate(-50%, -50%);
  width: 560px;
  height: 64px;
  animation: 3s 1 alternate slide;

  & .toast {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 32px;
    border: 2px solid black;
    padding-left: 66px;
    background: #ffd100;
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: center;
    gap: 4px;

    & h6 {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
    }

    & p {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }

    &::before {
      content: '';
      left: 8px;
      top: 8px;
      border-radius: 32px;
      position: absolute;
      height: 64px;
      width: 100%;
      border: 2px solid black;
      z-index: -1;
      background: black;
    }
  }
}

@keyframes slide {
  from {
    bottom: -100px;
  }
  to {
    transform: translate(-50%, -50%);
  }
}
.container {
  padding-bottom: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }

  &:not(:first-child) {
    padding-top: 30px;
  }
}

.section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.info {
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: 15px;
  flex-grow: 1;
}

.price {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.counter {
  margin-top: 26px;
}

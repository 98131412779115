.product {
  background-color: #fff;
  border-radius: 8px 0 0 8px;
  border: 1px solid #F0F0F0;
  padding: 16px 16px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__img {
    width: 80px;
    min-width: 80px;
    height: 80px;
    margin-right: 16px;
    object-fit: contain;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
}

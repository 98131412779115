.container {
  & .mainText {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    & h1 {
      font-size: 40px;
      line-height: 40px;
      font-weight: 700;
    }
  }

  & .subTextContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & .secondaryText {
      display: flex;
      justify-content: space-between;

      & h4,
      & h5 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
      }

      & h5 {
        font-weight: 600;
      }
    }
  }
}

.container {
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;

  & .modal {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    background: white;
    border-radius: 24px;
    height: 424px;
    width: 784px;
    padding: 56px 42px;

    & .lemonImage {
      position: relative;

      & .counter {
        position: absolute;
        background: #ffd100;
        font-size: 40px;
        line-height: 24px;
        padding: 4px;
        font-weight: 700;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      & img {
        height: 200px;
        width: 200px;
      }
    }

    & h2 {
      font-size: 40px;
      line-height: 40px;
      font-weight: 700;
      margin: 0 0 40px 0;
    }

    &::after {
      content: 'Tap anywhere';
      position: absolute;
      bottom: -44px;
      left: 50%;
      transform: translateX(-50%);
      color: white;
      opacity: 0.5;
      font-size: 18px;
      line-height: 20px;
      font-weight: 700;
    }
  }
}

.container {
  width: 178.4px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 209, 0, 0.24);
  border-radius: 8px;

  & h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
  }

  & p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  &.active {
    background: rgba(255, 209, 0, 1);
  }
}

.content {
  overflow: auto;
  max-height: calc(100vh - 231px);

  & > * {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
}

.footer {
  margin-top: 76px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.counter {
  height: 64px;
  width: 140px;
  padding: 12px 16px;
}

.addBtn {
  height: 64px;
  max-width: 206px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.body {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.body {
  overflow: auto;
  height: 100%;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

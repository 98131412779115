.container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  & #payBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    align-self: flex-end;
    width: 360px
  }
}

.container {
  height: 204px;
  width: 100%;
  background: #eeffe3;
  color: #4bac0b;
  border-radius: 24px;
  margin-top: 44px;
  margin-bottom: 60px;
  display: grid;
  place-items: center;

  & h5 {
    font-size: 24px;
    font-weight: 700;
  }

  & .sub_title {
    text-align: center;
    margin-top: 10px;
  }

  & .countdown {
    font-size: 46px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 10px;
  }

  & .error {
    color: #FF4545;
    text-align: center;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);

  & .emptyCart {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0.4;

    & h3 {
      margin-bottom: 8px;
    }

    & img {
      margin-bottom: 20px;
    }
  }

  & .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    max-height: calc(100vh - 200px);
  }

  .checkout {
    margin-top: auto;
    width: 100%;
    height: 64px;
  }
  .restart {
    width: 100%;
    outline: none;
    border: none;
    color: #867958;
    background-color: inherit;
  }
}

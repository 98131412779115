.container {
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    padding: 16px;
    margin-top: 24px;
    
    @media (max-width: 1080px) {
        margin: 70px 0;
      }
    
}
.product_item {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 30px 16px;
  border: 1px solid #a5bac3;

  .product_image {
    flex-grow: 1;

    img {
      max-width: 100%;
      max-height: 100%;
      width: 230px;
    }

    &__drink {
      img {
        width: 230px;
        height: 350px;
        object-fit: contain;

        @media (max-width: 1080px) {
          width: 262px;
        }
      
      }
    }
  }

  .product_title {
    display: inline-block;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000;
    margin-top: 20px;
  }

  .product_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 41px;
  }

  .product_price {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }

  .product_btn {
    cursor: pointer;

    &.inactive {
      filter: grayscale(1);
      cursor: default;
    }
  }

  img {
    display: block;
    width: 100%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: auto;
  margin-bottom: 50px;

  & .divider {
    height: 1px;
    width: 100%;
    background: rgba(0, 0, 0, 0.04);
    margin: 4px 0;
  }

  & .info {
    display: flex;
    justify-content: space-between;

    & .bonuses {
      display: flex;
      align-items: center;
      & img {
        padding-top: 5px;
        height: 32px;
      }
    }

    & h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
    }
    & h4,
    & h3 {
      font-size: 24px;
      line-height: 32px;
    }

    & h3 {
      font-weight: 600;
    }

    & h3 {
      font-weight: 500;
    }
  }
}

.container {
  padding: 16px 16px 24px 16px;
  border: 1px solid #a5bac3;
  border-radius: 8px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & .promo {
    align-self: baseline;
    border-radius: 4px;
    background: #ffeeb3;
    padding: 2px 8px;

    color: #db8016;

    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-transform: uppercase;
  }

  & .header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;

    & .close {
      position: absolute;
      top: -8px;
      right: -8px;
    }

    & .productImg {
      width: 80px;
      height: 80px;
      object-fit: contain;
    }

    .title {
      display: flex;
      width: 100%;

      & h2 {
        font-size: 20px;
        line-height: 24.2px;
        font-weight: 700;
      }

      & p {
        font-size: 20px;
        line-height: 24.2px;
        margin-left: 8px;
      }
    }
  }

  & .body {
    & h4 {
      font-size: 18px;
      font-weight: 700;
      line-height: 21.78px;
      margin-bottom: 4px;
    }

    & .optionsBox {
      & p {
        font-size: 18px;
        line-height: 21.78px;
        font-weight: 700;
        margin-bottom: 16px;
        & span {
          font-weight: 400;
        }
      }
    }

    & .topping {
      display: flex;
      justify-content: space-between;

      & p {
        font-size: 18px;
        line-height: 21.78px;
        font-weight: 400;
      }
    }
  }

  & .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    align-items: center;

    & .unUseBonus {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      background: none;

      & img {
        height: 100%;
        width: 28px;
        object-fit: contain;
        padding-top: 5px;
      }

      & span {
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        margin-right: 8px;
      }

      & p {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #ffd100;
      }
    }

    & .bonusButton {
      border: none;
      background: black;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 115px;
      gap: 6px;

      &:disabled {
        opacity: 0.3;
      }

      & img {
        height: 100%;
        width: 28px;
        object-fit: contain;
        padding-top: 5px;
      }

      & span {
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        color: #ffd100;
      }
    }

    & .price {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    & h2 {
      color: #FFDB00;
      font-size: 16px;
    }

    & h4 {
      font-size: 24px;
      font-weight: 700;
      line-height: 29.05px;
    }

    & h4.crossed {
      color: #A8B0B9;
      text-decoration: line-through;
    }
  }
}

.container {
    font-family: 'Inter', sans-serif;
    padding-top: 16px;
    padding-left: 140px;
    max-width: 1200px;
    width: 100%;
    margin-bottom: 20px;
  
    @media (max-width: 1080px) {
      padding-left: 15px;
    }
  
    .info {
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      margin-bottom: 65px;
    }
}  
.promo {
    border-radius: 8px;
    background: #fff;
    padding: 16px;
    font-family: 'Inter' sans-serif;
     
    &__header {
      text-align: right;
    }
  
    &__body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      margin-bottom: 16px;
    }
  
    &__title {
      color: #db8016;
  
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.32px;
  
      border-radius: 4px;
      background: #ffeeb3;
      padding: 2px 8px;
    }
  
    &__discount {
      color: #000;
  
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
    }
  
    &__footer {
      color: #a8b0b9;
  
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }

    &__btn {
        font-family: 'Inter', sans-serif;
        border: none;
        height: 40px;
        background: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #ffd100;
        float: right;
        cursor: pointer;
    }
  }
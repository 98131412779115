.container {
  margin-top: 24px;

  & h1 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }

  & .giftCards {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 300px;
    overflow: auto;
  }
}

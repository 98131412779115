.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;

  & .price {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & h1 {
    font-size: 24px;
    line-height: 29.05px;
    font-weight: 700;
  }

  & h2 {
    color: #FFDB00;
    font-size: 16px;
  }

  & h1.crossed {
    color: #A8B0B9;
    text-decoration: line-through;
  }
}

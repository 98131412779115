.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
}

.promotion {
  padding: 19px 19px 52px 16px;
  border: 1px solid #f0f0f0;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
  }

  &__title {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    line-height: 26.035px; /* 108.481% */
    letter-spacing: 0.48px;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 5px;
  }

  &__description {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.36px;
  }
}

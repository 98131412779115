.title {
  display: flex;
  gap: 7px;
  align-items: center;
  color: #3c3c3c;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  margin-top: 15px;
  width: 100%;

  @media (max-width: 1080px) {
    grid-template-columns: repeat(2, 50%);
  }
}

.pagination {
  display: flex;
  align-items: center;
  margin-top: 10px;

  .divider {
    flex-grow: 1;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.20);
    margin: 0 25px;
  }

  &_btn {
    border: none;
    outline: none;
    background: inherit;

    color: #3c3c3c;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 90% */
    cursor: pointer;
    &.active {
      color: #FFD100
    }
  }
}

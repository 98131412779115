.container {
  z-index: 111;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);

  & .modal {
    position: absolute;
    width: 400px;
    background: white;
    bottom: 0;
    right: 0;
    padding: 16px;

    & #login, & #loginAsGuest {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      height: 56px;
      font-size: 14px;
      width: 100%;
    }

    & #loginAsGuest {
      border: 2px solid #d6dade;
      margin-top: 8px;
    }

    & .close {
      border: none;
      background: rgba(0, 0, 0, 0.08);
      border-radius: 100%;
      height: 40px;
      width: 40px;
      display: grid;
      place-items: center;
      cursor: pointer;

      & img {
        height: 12px;
        width: 12px;
      }
    }

    & h1 {
      margin-top: 14px;
      font-size: 22px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #DB8016;
    }

    & h2 {
      margin-bottom: 24px;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
    }
  }
}

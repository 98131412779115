.keyboard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  & button {
    cursor: pointer;
    width: 140px;
    height: 88px;
    border: 1.15px solid #f0f0f0;
    border-radius: 46.11px;
    background: white;
    font-size: 32.28px;
    font-weight: 600;
    line-height: 48.42px;

    & img {
      height: 18.44px;
    }
  }
}

.container {
  width: 100%;
  height: 100vh;
  background: #fafafa;

  & .content {
    padding-top: 65px;

    & .buttonGroup {
      display: flex;
      justify-content: space-between;
      height: 171px;
      align-items: flex-end;
      padding: 0 16px 16px 16px;
      background: #ececec;

      @media (max-width: 1080px) {
        gap: 10px;
      }

      &.membership {
        height: 69px;
        background: #fafafa;

        & #continue {
          display: none !important;
        }
      }
    }

    & ul {
      width: 468px;
      margin: 0 auto 64px auto;

      & li {
        padding-bottom: 23px;
      }
    }

    & input,
    & h5 {
      width: 468px;
    }
  }
}

.button {
  height: 72px;
  border-radius: 200px;
  color: black;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;

  &.primary {
    background: #ffd100;
    border: 2px solid black;

    &:disabled {
      border: 2px solid #ffffff;
      background: #c8c8c8;
      color: white;
    }
  }
  &.secondary {
    border: 2px solid black;
    background: white;
  }

  &.small {
    width: 338px;
  }

  &.big {
    width: 468px;
  }

  &:disabled {
    color: #bcbcbc;
    background: #ffffff;
  }
}

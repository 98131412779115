.container {
  & .continue {
    width: 100%;
    margin-top: 119px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    padding: 24px 0;
    background: #ffd100;
    border: 2px solid black;
    border-radius: 200px;
  }

  & input {
    pointer-events: none;
  }
}


.container {
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 8px 0 0 8px;
  padding: 16px 16px 30px;
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 30px;
}

.content {
  padding: 0 16px 16px 16px;
  display: flex;
  gap: 40px;
  flex-grow:1;

  & .leftSection {
    width: 50%;
  }
}

.carousel {
  position: relative;
  width: 100%;

  @media (max-width: 1120px) {
    height: 50vh;
  }

  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity .2s ease-in-out;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & img.active {
    opacity: 1 !important;
  }
}


.container {
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1080px) {
    margin: 450px 0;
  }

  & .title {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 24px;
    margin: 0 auto;
  }

  & img {
    height: 633px;
    object-fit: contain;
    margin-bottom: 41px;
  }

  & input {
    position: absolute;
    left: 50%;
    bottom: -90px;
    transform: translate(-50%, -50%);
    height: 72px;
    pointer-events: all;
    width: 388px;
    border-radius: 80px;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    margin: 0 !important;
    border: 1px solid #a5bac3;
    letter-spacing: 3px;

    &::placeholder {
      color: #bcbcbc;
      letter-spacing: 0;
    }

    &.hasError {
      color: red;
      border: 1px solid #ff4545;
    }
  }
}

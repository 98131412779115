.container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .buttonGroup {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}

.categories_container {
    position: fixed;
    height: 100vh;

    .heading {
        display: inline-block;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        margin-bottom: 15px;
    }
    
    .categories_list {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 18px;
    }

    .start_over {
        position: absolute;
        bottom: 35px;
        width: 100%;
    }
}



.container {
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 8px 0 0 8px;
  padding: 16px 16px 30px;
}

.title {
  color: #000000;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 38px;

  span {
    color: #BCBCBC;
  }
}

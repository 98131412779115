.container {
  & h1 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 2px;
  }

  & .inputBox {
    display: flex;
    gap: 8px;

    & input {
      padding: 14px 12px;
      height: 48px;
      border: 1px solid #A5BAC3;
      border-radius: 4px;
      font-size: 14px;
      min-width: 160px;

      &::placeholder {
        color: #a8b0b9;
      }
    }

    & #inputNumber {
      width: 100%;
    }

    & #inputPin {
      width: auto;
    }

    & button {
      border-radius: 100%;
      background: #ffd100;
      border: 2px solid black;
      width: 48px;
      height: 48px;
      min-width: 48px;
      font-size: 30px;
      font-weight: 500;
    }

    & button:disabled {
      background: #a8b0b9;
    }
  }
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 55px;
}

.text {
  position: absolute;
  min-width: 157px;
  background-color: #fff;
  right: 80px;
  padding: 12px 16px;
  animation: opacity 2s forwards;
  border: 2px solid #000;

  & .triangle {
    width: 0;
    height: 0;

    position: absolute;

    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;

    border-left: 10px solid #000;

    &::before {
      content: '';
      width: 0;
      height: 0;
        position: absolute;
      top: -8px;
      left: -10px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid #fff;
    }
  }

  &.hide {
    display: none;
  }
}

.wrapper {
  position: relative;
  bottom: 0;
  transform: translateX(200%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background-color: #ffd100;
  border: 2.667px solid #000;
  border-radius: 32px;
  cursor: pointer;
  animation: slideOut 1.5s forwards;
}

@keyframes slideOut {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.category_item {
    height: 64px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: #F5F5F5;

        svg {
            opacity: 0.3;
        }
    }

    svg {
        opacity: 0;
    }
}

.category_item.active {
    background-color: #FFD100;
}

.category_title {
    margin-right: 10px;
    text-transform: capitalize;
}
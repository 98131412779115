.wrapper {
    z-index: 111;
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.85);
  
    & .content {
			display: flex;
			flex-direction: column;
			gap: 32px;
      position: absolute;
      width: 536px;
      background: white;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 56px 24px;
			align-items: center;
			border-radius: 24px;
			
			box-shadow: 2px 2px 24px 0px rgba(0, 0, 0, 0.16);
  
      & #login, & #loginAsGuest {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
        height: 56px;
        font-size: 14px;
        width: 100%;
      }
  
      & #loginAsGuest {
        border: 2px solid #000;
		margin-top: 20px;
      }

			.circle {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 188px;
				height: 188px;
				border: 1px solid #FFE64B;
				background: #FFE64B;
				border-radius: 50%;
			}

			& .conteiner {
				width: 100%;
			}

			& h5 {
				color: #3C3C3C;
				text-align: center;
				font-size: 40px;
				font-weight: 700;
				line-height: 40px;
			}

			& p {
				color: rgba(0, 0, 0, 0.80);
				text-align: center;
				font-size: 20px;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: 0.4px;
			}
    }
}
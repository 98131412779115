.container {
  background-color: #FAFAFA;
  border-radius: 8px;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 100;
  padding: 19px 16px 12px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 50;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #3C3C3C;
}

.closeIcon {
  cursor: pointer;
}

.body {
  max-height: 100%;
}

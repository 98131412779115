.container {
  padding: 14px 16px 18px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & img {
    height: 56px;
  }

  & .pointBalance {
    background: none;
    border: 2px solid black;
    border-radius: 40px;
    height: 40px;
    min-width: 81px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    img {
      padding-top: 5px;
      height: 100%;
    }
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

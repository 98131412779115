.products_container {
  padding: 19px 0 0 141px;
  max-width: 1200px;
  font-family: 'Inter', sans-serif;
  margin-bottom: 240px;
  width: 100%;

  @media (max-width: 1080px) {
    padding: 19px 15px 0 15px;
  }

  .products_list {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    margin-top: 15px;
    width: 100%;

    @media (max-width: 1080px) {
      grid-template-columns: repeat(2, 50%);
    }
  }
}

.container {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  overflow: auto;
  width: 100vw;
  background: #fafafa;
  z-index: 9;

  & #back {
    position: fixed;
    bottom: 16px;
    left: 16px;
  }

  & .content {
    margin: 160px auto 0 auto;

    @media (max-width: 1080px) {
      margin-top: 400px;
    }

    & h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 89px;
    }
    & input {
      margin-bottom: 80px;
    }

    & .confirm {
      margin-top: 119px;
    }
  }
}

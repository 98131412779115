.buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.start_over {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 273px !important;
    z-index: 51;
}

.scan_here {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  
    width: 273px !important;
    z-index: 51;
}

.login {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 273px !important;
    z-index: 51;
}

.btn {
    height: 100vh;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
}
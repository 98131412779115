.container {
  display: flex;
  padding: 11px;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d6dade;

  & .info {
    display: flex;

    & img {
      height: 24px;
      margin-right: 8px;
    }

    & h6,
    & h5 {
      font-size: 16px;
      line-height: 24px;
    }

    & h6 {
      font-weight: 500;
      margin-right: 32px;
    }
  }

  & button {
    width: 120px;
    height: 56px;
    border-radius: 4px;
    border: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #d6dade;

    &.used {
      background: #ffd100;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & h4 {
    font-size: 18px;
    line-height: 21.78px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  & .optionsBox {
    display: flex;
    flex-direction: column;
    gap: 16px;
    & p {
      font-size: 18px;
      line-height: 21.78px;
      font-weight: 700;
      & span {
        font-weight: 400;
      }
    }
  }

  & .topping {
    display: flex;
    justify-content: space-between;

    & p {
      font-size: 18px;
      line-height: 21.78px;
      font-weight: 400;
      & span {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

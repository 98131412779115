.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  background: rgba(0, 0, 0, 0.85);

  .modal {
    position: relative;
    padding: 52px 58px;
    background-color: #fff;
    border-radius: 17px;

    .close {
        position: absolute;
        top: 10px;
        right: 15px;
      border: none;
      background: rgba(0, 0, 0, 0.08);
      border-radius: 100%;
      height: 40px;
      width: 40px;
      display: grid;
      place-items: center;
      cursor: pointer;

      & img {
        height: 12px;
        width: 12px;
      }
    }

    &__img {
      width: 400px;
      height: 350px;
      object-fit: cover;
      margin-bottom: 20px;
    }

    &__input {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      label {
        font-weight: 700;
        font-size: 32px;
        line-height: 24px;
        color: #000000;
      }

      input {
        height: 52px;
        width: 100%;
        pointer-events: all;
        border-radius: 80px;
        font-size: 20px;
        text-align: center;
        font-weight: 700;
        margin: 0 !important;
        border: 1px solid #a5bac3;
        letter-spacing: 3px;

        &::placeholder {
          color: #bcbcbc;
          letter-spacing: 0;
        }
      }
    }
  }
}

.tabs {
  display: flex;
  list-style: none;

  & li {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    width: 100%;
    color: rgba(0, 0, 0, 0.4);

    &.active {
      border-bottom: 2px solid black;
      color: black;
    }
  }
}

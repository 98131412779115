$bg-color: #FFD100;

.label {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  height: 24px;
  padding-left: 39px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;

  input:checked ~ .checkmark {
    background-color: $bg-color;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 9px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border: 2px solid #000000;
  border-radius: 6px;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.disabled {
  .checkmark {
    background-color: #D6DADE;
    border-color: #D6DADE;
  }
}

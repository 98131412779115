.loader_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 85%;

    .loader {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .loader_img_container {
        width: 150px;
        display: flex;
        justify-content: space-between;
    }

    .loader_title {
        font-weight: 700;
        font-size: 20px;
        line-height: 18px;
        margin-top: 15px;
    }
}
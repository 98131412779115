.container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  inset: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.85);

  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 32px;
    gap: 32px;
    border-radius: 24px;
    background: #fff;
    box-shadow: 2px 2px 24px 0px rgba(0, 0, 0, 0.16);
  }

  & .title {
    color: #3c3c3c;

    font-size: 40px;
    font-weight: 700;
    line-height: 40px; /* 100% */
    max-width: 700px;
  }

  & img {
    max-width: 700px;
  }

  & button {
    width: 100%;
    height: 72px;

    border-radius: 200px;
    border: 2px solid #000;
    background: #ffd100;

    color: #000;

    font-size: 20px;
    font-weight: 700;
    line-height: 24px; /* 120% */

    cursor: pointer;
  }
}

.button {
  position: sticky;
  top: 52px;
  margin: 52px 46px 0 auto;
  align-self: flex-start;
  background: #ffd100;
  border-radius: 32px;
  height: 64px;
  min-width: 128px;
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (max-width: 1080px) {
    margin-right: 23px;
    margin-left: 23px;
  }

  & .counter {
    background: white;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 2000px;
    padding: 4px 5.5px;
  }
}

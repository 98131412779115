.container {
  max-height: 74vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 1080px) {
    max-height: 85vh;
  }
}

.container {
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99998;

  & .content {
    padding: 56px 60px;
    background: white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .list {
      margin: 0 auto;
      margin-bottom: 24px;
    }

    & h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 8px;
    }

    & p {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }

    & .btnGot {
      width: 440px;
      height: 56px;
    }
  }
}

.item {
  display: flex;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.check {
  margin-right: 15px;
  border: 2px solid #000000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: relative;

  &__active {
    background: #FFD100;

    &:after {
      left: 7px;
      top: 4px;
      width: 4px;
      height: 8px;
      border: solid black;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      content: "";
      position: absolute;
    }
  }
}

